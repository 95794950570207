import { FooterCssVariables } from 'config/branding/interfaces/footer';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const footerCssVariables: FooterCssVariables = {
    '--color-bg-footer-primary': 'var(--color-base-white)',
    '--color-border-footer-primary': 'var(--color-base-black)',
    '--color-border-footer-hover': 'var(--color-base-brand-red)',
    '--fill-logo-footer': 'var(--color-base-brand-red)',
    '--fill-ACT-logo-footer': 'var(--color-base-black)',
    '--fill-logo-footer-site-selector': 'var(--color-base-black)',
};
