import { get } from '@activebrands/core-web/libs/grebcommerce/fetch';
import { addLocalePrefix } from '@activebrands/core-web/utils/add-locale-prefix';

const getchProductsFromGcJson = async (ids: Array<string>) => {
    if (!ids || ids?.length === 0) {
        return [];
    }

    const urls = ids.reduce(
        (previousValue: Array<string>, currentValue) => [
            ...previousValue,
            addLocalePrefix(`/gc-json/product_${currentValue}.json`, false),
        ],
        []
    );

    //Resolve all promises
    const responses = await Promise.allSettled(
        urls.map(async (url: string): Promise<any> => {
            return await get(url, {}, window.location.origin);
        })
    );

    return await responses
        .filter((currentValue: any) => currentValue?.value?.data)
        .reduce((previousValue: Array<object>, currentValue: any) => [...previousValue, currentValue.value.data], []);
};

export default getchProductsFromGcJson;
