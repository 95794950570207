import { CheckoutCssVariables } from 'config/branding/interfaces/checkout';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const checkoutCssVariables: CheckoutCssVariables = {
    '--color-bg-checkout-act-content': 'var(--color-base-brand-lightgrey)',
    '--color-bg-checkout-act-information': 'var(--color-base-white)',
    '--color-bg-checkout-act-vouchers-active': 'var(--color-base-black)',
    '--color-bg-checkout-act-vouchers': 'var(--color-base-white)',
    '--color-bg-checkout-info-boxes': 'var(--color-base-brand-lightgrey)',
    '--color-bg-checkout-response-page-content': 'var(--color-base-white)',
    '--color-bg-checkout-summary-products': 'var(--color-base-brand-lightgrey)',
    '--color-bg-checkout-summary-total': 'var(--color-base-brand-lightgrey)',
    '--color-bg-checkout-summary-vouchers-autofill': 'var(--color-palette-neutral-200)',
    '--color-bg-checkout-summary': 'var(--color-base-brand-lightgrey)',
    '--color-bg-checkout': 'var(--color-base-white)',
    '--color-border-checkout-act-content': 'var(--color-base-brand-black)',
    '--color-border-checkout-act-information': 'var(--color-base-brand-black)',
    '--color-border-checkout-act-vouchers-active': 'var(--color-base-black)',
    '--color-border-checkout-act-vouchers': 'var(--color-base-brand-grey-light)',
    '--color-border-checkout-info-boxes': 'var(--color-base-black)',
    '--color-border-checkout-response-page': 'var(--color-base-brand-lightgrey)',
    '--color-border-checkout-subtle': 'var(--color-base-black-10)',
    '--color-border-checkout': 'var(--color-base-brand-black)',
    '--color-icon-checkout-act-content': 'var(--color-palette-act-blue)',
    '--color-text-checkout-act-information': 'var(--color-base-brand-black)',
    '--color-text-checkout-act-vouchers-active': 'var(--color-base-white)',
    '--color-text-checkout-act-vouchers': 'var(--color-base-black)',
    '--color-text-checkout-error': 'var(--color-base-dange)',
    '--color-text-checkout-steps-active': 'var(--color-base-brand-red)',
    '--color-text-checkout-steps': 'var(--color-base-brand-black)',
    '--color-text-checkout-subtle': 'var(--color-text-checkout-subtle)',
};
