import { HeaderCssVariables } from 'config/branding/interfaces/header';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const headerCssVariables: HeaderCssVariables = {
    '--color-bg-header': 'var(--color-base-white)',
    '--color-bg-search-header': 'var(--color-base-white)',
    '--color-bg-search-footer': 'var(--color-base-white)',
    '--color-border-bottom-header': 'var(--color-base-white)',
    '--color-border-top-header': 'var(--color-base-brand-black)',
    '--color-border-search-header': 'var(--color-base-black-25)',
    '--color-border-search-header-value': 'var(--color-base-brand-grey)',
    '--color-border-search-footer': 'var(--color-base-brand-black)',
    '--color-text-header': 'var(--color-base-brand-black)',
    '--color-text-search-header': 'var(--color-base-brand-black)',
    '--color-top-logo-mobile': 'var(--color-base-brand-black)',
    '--color-logo-header': 'var(--color-base-brand-red)',
    '--color-top-logo-site-selector': 'var(--color-base-white)',
};
