import { request } from '@activebrands/core-web/libs/fetch';

export const SignIn = async (email: string, password: string) =>
    await request({
        method: 'POST',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/login',
        data: { email, password },
    });

export const GetUser = async (token: string | null) =>
    await request({
        method: 'GET',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer',
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const GetUserVouchers = async (token: string | null) =>
    await request({
        method: 'GET',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/member',
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const GetOrders = async (token: string) =>
    await request({
        method: 'GET',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/orders',
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const UpdateUser = async (
    {
        acknowledgement,
        addressLine1,
        birthday,
        city,
        country,
        email,
        firstname,
        gender,
        interestParams,
        lastname,
        mobilePhone,
        postalCode,
        permissions,
    },
    token: string
) => {
    return await request({
        method: 'PUT',
        data: {
            acknowledgement,
            addressLine1,
            birthday,
            city,
            country,
            email,
            firstname,
            gender,
            ...interestParams,
            lastname,
            mobilePhone,
            postalCode,
            permissions,
        },
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/update',
        headers: { 'Authorization': `Bearer ${token}` },
    });
};

export const UpdatePreferences = async (token: string, data: any) =>
    await request({
        method: 'POST',
        data,
        url: '/account/voyado/accepts',
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const ChangePassword = async ({ email, oldPassword, newPassword }, token: string) => {
    return await request({
        method: 'PUT',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/changepassword',
        data: { email, oldPassword, newPassword },
        headers: { 'Authorization': `Bearer ${token}` },
    });
};

export const ResetPassword = async (email: string) =>
    await request({
        method: 'POST',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/customer/resetpassword',
        data: { email },
    });

export const SendResetPasswordLink = async (email: string, aliasId: number) =>
    await request({
        method: 'POST',
        url: '/account/reset-password',
        data: {
            email,
            'application_alias_id': aliasId,
        },
    });

export const CreateUser = async (email, password) =>
    await request({
        method: 'POST',
        url: '/api/v1/customer/register',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        data: {
            email: email,
            password: password,
        },
    });

export type NewsletterSignUpBrand = 'Dæhlie' | 'Johaug' | 'Kari Traa' | 'Sweet Protection';
export type NewsletterSignUpConsent = 'OptIn';
export type NewsletterSignUpContactType = 'Email';
export type NewsletterSignUpLanguageCode = 'EU' | 'NO' | 'US';

interface NewsletterSignUpData {
    brand: NewsletterSignUpBrand;
    consent: NewsletterSignUpConsent;
    contactInformation: string;
    contactType: NewsletterSignUpContactType;
    language: NewsletterSignUpLanguageCode;
}

export const SignUpNewsletter = async ({
    brand,
    consent,
    contactInformation,
    contactType,
    language,
}: NewsletterSignUpData) => {
    return await request({
        method: 'POST',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL,
        data: {
            brand,
            consent,
            contactInformation,
            contactType,
            language,
        },
        url: 'mail/members',
    });
};

export const IsEmailRegistered = async (email: string) =>
    await request({
        method: 'GET',
        url: `account/voyado/exists`,
        data: {
            email,
        },
    });

export const ValidateTeamStoreAccount = async ({ teamId, teamPassword }, token: string) =>
    await request({
        method: 'PUT',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: 'api/v1/teamwear/upsertmarketinglist',
        data: { firebaseIdToken: token, teamId, teamPassword },
        headers: { 'Authorization': `Bearer ${token}` },
    });

export const GetTeamStoreData = async ({ teamId }, token: string | undefined) =>
    await request({
        method: 'GET',
        baseURL: process.env.REACT_APP_GREBCOMMERCE_BACKEND_ACT_API_URL,
        url: `api/v1/teamwear/teamproductlist/${teamId}`,
        headers: { 'Authorization': `Bearer ${token}` },
    });
