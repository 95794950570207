import ColorsCssVariables from '@activebrands/core-web/interfaces/colors';
import { CssVariables, HEX, RGB, RGBA } from '@activebrands/core-web/types/css-types';

export interface ColorCssVariables extends CssVariables<RGBA | RGB | HEX> {
    '--color-base-black-10': RGBA | RGB | HEX;
    '--color-base-black-25': RGBA | RGB | HEX;
    '--color-base-black-50': RGBA | RGB | HEX;
    '--color-base-black-75': RGBA | RGB | HEX;
    '--color-base-black': RGBA | RGB | HEX;
    '--color-base-brand-black': RGBA | RGB | HEX;
    '--color-base-brand-grey': RGBA | RGB | HEX;
    '--color-base-brand-lightgrey': RGBA | RGB | HEX;
    '--color-base-brand-red': RGBA | RGB | HEX;
    '--color-base-brand-white': RGBA | RGB | HEX;
    '--color-base-error': RGBA | RGB | HEX;
    '--color-base-lightgrey-50': RGBA | RGB | HEX;
    '--color-base-red-75': RGBA | RGB | HEX;
    '--color-base-success': RGBA | RGB | HEX;
    '--color-base-white-10': RGBA | RGB | HEX;
    '--color-base-white-25': RGBA | RGB | HEX;
    '--color-base-white-50': RGBA | RGB | HEX;
    '--color-base-white-75': RGBA | RGB | HEX;
    '--color-base-white': RGBA | RGB | HEX;
}

export const colorsCssVariables: ColorsCssVariables = {
    // Default colors
    '--color-base-white': '#FFFFFF',
    '--color-base-black': '#000000',

    // Brand colors
    '--color-base-brand-black': '#121413',
    '--color-base-brand-grey': '#595959',
    '--color-base-brand-lightgrey': '#f4f4f4',
    '--color-base-brand-red': '#E31100',
    '--color-base-brand-white': '#f1f1f1',

    // Transparency colors
    '--color-base-black-75': 'rgba(18, 20, 19, 0.75)',
    '--color-base-black-50': 'rgba(18, 20, 19, 0.50)',
    '--color-base-black-25': 'rgba(18, 20, 19, 0.25)',
    '--color-base-black-10': 'rgba(18, 20, 19, 0.10)',

    '--color-base-white-75': 'rgba(246, 245, 242, 0.75)',
    '--color-base-white-50': 'rgba(246, 245, 242, 0.50)',
    '--color-base-white-25': 'rgba(246, 245, 242, 0.25)',
    '--color-base-white-10': 'rgba(246, 245, 242, 0.10)',

    '--color-base-lightgrey-50': 'rgba(244, 244, 244, 0.50)',

    '--color-base-red-75': 'rgba(227, 17, 0, 0.3)',

    // Informational
    '--color-base-error': '#CC0000',
    '--color-base-success': '#2CBF4C',
};
