import { ButtonCssVariables } from 'config/branding/interfaces/buttons';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const buttonsCssVariables: ButtonCssVariables = {
    // ----------------------------//
    // Default
    // ---------------------------//
    '--color-bg-button-default': 'var(--color-base-brand-black)',
    '--color-text-button-default': 'var(--color-base-white)',
    '--transition-all-button-default': 'var(--transition-fast)',

    // Default (Hover)
    '--color-bg-button-default-hover': 'var(--color-base-brand-red)',
    '--color-text-button-default-hover': 'var(--color-base-white)',

    // Default (Active)
    '--color-bg-button-default-active': 'var(--color-base-brand-red)',
    '--color-text-button-default-active': 'var(--color-base-white)',

    // Default (Disabled)
    '--color-bg-button-default-disabled': 'var(--color-base-brand-grey)',
    '--color-text-button-default-disabled': 'var(--color-base-white-50)',

    // ----------------------------//
    // Inverted
    // ---------------------------//
    '--color-bg-button-inverted': 'var(--color-base-white)',
    '--color-text-button-inverted': 'var(--color-base-brand-black)',
    '--transition-all-button-inverted': 'var(--transition-fast)',

    // Light (Hover)
    '--color-bg-button-inverted-hover': 'var(--color-base-brand-red)',
    '--color-text-button-inverted-hover': 'var(--color-base-white)',

    // Light (Active)
    '--color-bg-button-inverted-active': 'var(--color-base-brand-red)',
    '--color-text-button-inverted-active': 'var(--color-base-white)',

    // Light (Disabled)
    '--color-bg-button-inverted-disabled': 'var(--color-base-brand-grey)',
    '--color-text-button-inverted-disabled': 'var(--color-base-white-50)',

    // ----------------------------//
    // Highlight
    // ---------------------------//
    '--color-bg-button-highlight': 'var(--color-base-brand-red)',
    '--color-text-button-highlight': 'var(--color-base-brand-white)',
    '--transition-all-button-highlight': 'var(--transition-fast)',

    // Highlight (Hover)
    '--color-bg-button-highlight-hover': 'var(--color-base-brand-black)',
    '--color-text-button-highlight-hover': 'var(--color-base-white)',

    // Highlight (Active)
    '--color-bg-button-highlight-active': 'var(--color-base-brand-black)',
    '--color-text-button-highlight-active': 'var(--color-base-white)',

    // Highlight (Disabled)
    '--color-bg-button-highlight-disabled': 'var(--color-base-brand-grey)',
    '--color-text-button-highlight-disabled': 'var(--color-base-white-50)',

    // ----------------------------//
    // Default - Secondary
    // ---------------------------//
    '--color-bg-button-defaultsecondary': 'var(--color-base-white)',
    '--color-text-button-defaultsecondary': 'var(--color-base-brand-black)',
    '--color-border-button-defaultsecondary': 'var(--color-base-brand-black)',
    '--transition-all-button-defaultsecondary': 'var(--transition-fast)',

    // Default Secondary (Hover)
    '--color-bg-button-defaultsecondary-hover': 'var(--color-base-white)',
    '--color-text-button-defaultsecondary-hover': 'var(--color-base-brand-red)',
    '--color-border-button-defaultsecondary-hover': 'var(--color-base-brand-red)',

    // Default Secondary (Active)
    '--color-bg-button-defaultsecondary-active': 'var(--color-base-brand-black)',
    '--color-text-button-defaultsecondary-active': 'var(--color-base-brand-white)',
    '--color-border-button-defaultsecondary-active': 'var(--color-base-brand-black)',

    // Default Secondary (Disabled)
    '--color-bg-button-defaultsecondary-disabled': 'var(--color-base-white)',
    '--color-text-button-defaultsecondary-disabled': 'var(--color-base-brand-grey)',
    '--color-border-button-defaultsecondary-disabled': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // Inverted - Secondary
    // ---------------------------//
    '--color-bg-button-invertedsecondary': 'var(--color-base-brand-black)',
    '--color-text-button-invertedsecondary': 'var(--color-base-white)',
    '--color-border-button-invertedsecondary': 'var(--color-base-white)',
    '--transition-all-button-invertedsecondary': 'var(--transition-fast)',

    // Inverted - Secondary (Hover)
    '--color-bg-button-invertedsecondary-hover': 'var(--color-base-white)',
    '--color-text-button-invertedsecondary-hover': 'var(--color-base-brand-red)',
    '--color-border-button-invertedsecondary-hover': 'var(--color-base-brand-red)',

    // Inverted - Secondary (Active)
    '--color-bg-button-invertedsecondary-active': 'var(--color-base-white)',
    '--color-text-button-invertedsecondary-active': 'var(--color-base-brand-red)',
    '--color-border-button-invertedsecondary-active': 'var(--color-base-brand-red)',

    // Inverted - Secondary (Disabled)
    '--color-bg-button-invertedsecondary-disabled': 'var(--color-base-brand-black)',
    '--color-text-button-invertedsecondary-disabled': 'var(--color-base-brand-grey)',
    '--color-border-button-invertedsecondary-disabled': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // Highlight - Secondary
    // ---------------------------//
    '--color-bg-button-highlightsecondary': 'var(--color-base-white)',
    '--color-text-button-highlightsecondary': 'var(--color-base-brand-red)',
    '--color-border-button-highlightsecondary': 'var(--color-base-brand-red)',
    '--transition-all-button-highlightsecondary': 'var(--transition-fast)',

    // Highlight - Secondary (Hover)
    '--color-bg-button-highlightsecondary-hover': 'var(--color-base-white)',
    '--color-text-button-highlightsecondary-hover': 'var(--color-base-brand-black)',
    '--color-border-button-highlightsecondary-hover': 'var(--color-base-brand-black)',

    // Highlight - Secondary (Active)
    '--color-bg-button-highlightsecondary-active': 'var(--color-base-white)',
    '--color-text-button-highlightsecondary-active': 'var(--color-base-brand-black)',
    '--color-border-button-highlightsecondary-active': 'var(--color-base-brand-black)',

    // Highlight - Secondary (Disabled)
    '--color-bg-button-highlightsecondary-disabled': 'var(--color-base-white)',
    '--color-text-button-highlightsecondary-disabled': 'var(--color-base-brand-grey)',
    '--color-border-button-highlightsecondary-disabled': 'var(--color-base-brand-grey)',

    // ----------------------------//
    // Highlight - Inverted - Secondary
    // ---------------------------//
    '--color-bg-button-highlightinvertedsecondary': 'var(--color-base-white)',
    '--color-text-button-highlightinvertedsecondary': 'var(--color-base-brand-black)',
    '--color-border-button-highlightinvertedsecondary': 'var(--color-base-brand-black)',
    '--transition-all-button-highlightinvertedsecondary': 'var(--transition-fast)',

    // Highlight - Inverted - secondary (Hover)
    '--color-bg-button-highlightinvertedsecondary-hover': 'var(--color-base-white)',
    '--color-text-button-highlightinvertedsecondary-hover': 'var(--color-base-brand-red)',
    '--color-border-button-highlightinvertedsecondary-hover': 'var(--color-base-brand-red)',

    // Highlight - Inverted - Secondary (Active)
    '--color-bg-button-highlightinvertedsecondary-active': 'var(--color-base-brand-black)',
    '--color-text-button-highlightinvertedsecondary-active': 'var(--color-base-white)',
    '--color-border-button-highlightinvertedsecondary-active': 'var(--color-base-brand-black)',

    // Highlight - Inverted - Secondary (Disabled)
    '--color-bg-button-highlightinvertedsecondary-disabled': 'var(--color-base-white)',
    '--color-text-button-highlightinvertedsecondary-disabled': 'var(--color-base-brand-grey)',
    '--color-border-button-highlightinvertedsecondary-disabled': 'var(--color-base-brand-grey)',

    /** Link */
    '--color-text-button-link': 'var(--color-base-brand-black)',

    // Link (Hover)
    '--color-bg-button-link-hover': 'var(--color-base-brand-red)',

    // Link (Active)
    '--color-text-button-link-active': 'var(--color-base-brand-red)',
    '--color-border-button-link-active': 'var(--color-base-brand-red)',

    // ----------------------------//
    // Link - Header
    // ---------------------------//
    '--color-text-button-linkheader': 'var(--color-base-brand-black)',

    // Link - Header (Hover)
    '--color-bg-button-linkheader-hover': 'var(--color-base-white)',
    '--color-text-button-linkheader-hover': 'var(--color-base-brand-red)',
    '--color-border-button-linkheader-hover': 'var(--color-base-brand-red)',

    // Link - Header (Active)
    '--color-text-button-linkheader-active': 'var(--color-base-brand-red)',
    '--color-border-button-linkheader-active': 'var(--color-base-brand-red)',
};
