import { ModulesCssVariables } from 'config/branding/interfaces/modules';

/* Variable format:
 * --property-[where]-[component]-[state]
 * For example: color-text-button-active, height-header, etc.
 */

export const modulesCssVariables: ModulesCssVariables = {
    '--color-bg-discover-module': 'var(--color-base-white)',
    '--color-bg-information-module': 'var(--color-base-white)',

    // ----------------------------//
    // Shop the look
    // ---------------------------//

    '--color-bg-shop-the-look-text-default': 'var(--color-base-black)',
    '--color-bg-shop-the-look-text-inverted': 'var(--color-base-white)',
    '--color-bg-shop-the-look-text-hightlight': 'var(--color-base-brand-red)',

    '--color-text-shop-the-look-text-default': 'var(--color-base-white)',
    '--color-text-shop-the-look-text-inverted': 'var(--color-base-black)',
    '--color-text-shop-the-look-text-hightlight': 'var(--color-base-white)',
};
