import { CssResponsiveVariables } from '@activebrands/core-web/types/css-types';

/**
 * Use this as a guidence in catacombs of the the z-index horror.
 * We have partet this in to three sections
 * background - as name says, backgrounds and content that going to act as a "canvas" for something else
 * middleground - here we want to display the "main" content, such as header, footer search etc
 * forground - Use as example states below for overlays notifications etc. Displays above anything else.
 * if ex: you want something displayed just below the header you can use calc(var(--zindex-header) - 1)
 */

export interface ZIndexCssResponsiveVariables {
    '--zindex-background'?: number;
    '--zindex-middleground'?: number;
    '--zindex-header'?: number;
    '--zindex-foreground'?: number;
    '--zindex-popup'?: number;
    '--zindex-overlay'?: number;
    '--zindex-notifications'?: number;
}

export const zIndexCssResponsiveVariables: CssResponsiveVariables<ZIndexCssResponsiveVariables> = {
    'mobile.sm': {
        '--zindex-background': 1,
        '--zindex-overlay-header': 5,
        '--zindex-middleground': 500,
        '--zindex-banner': 525,
        '--zindex-search': 530,
        '--zindex-basket': 550,
        '--zindex-filter-bar': 555,
        '--zindex-top-logo': 555,
        '--zindex-overlay': 558,
        '--zindex-navigation': 559,
        '--zindex-filter': 559,
        '--zindex-header': 560,
        '--zindex-pdp-gallery': 561,
        '--zindex-wrong-site-popup': 570,
        '--zindex-popup': 575,

        '--zindex-foreground': 1000,
        '--zindex-notifications': 1150,
    },
    'mobile.md': {},
    'mobile.lg': {},
    'tablet.sm': {},
    'tablet.lg': {},
    'desktop.sm': {
        '--zindex-filter': 960,
    },
    'desktop.md': {},
    'desktop.lg': {},
};
