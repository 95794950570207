import parseError from '@activebrands/core-web/utils/auth/parse-error';
import axios, { AxiosRequestConfig } from 'axios';

export interface Request {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE';
    url: string;
    data?: any;
    baseURL?: string;
    headers?: Record<string, any>;
}

/**
 * @typedef {Object} FetchOptions
 * @property {('GET'|'POST'|'PUT'|'DELETE')} method
 * @property {String} url Relative url
 * @property {Object} data Request body / query options
 * @property {String} baseURL Base URL. Defaults to `REACT_APP_GREBCOMMERCE_BACKEND_API_URL`
 * @property {Object} headers Request headers
 *
 * @param {FetchOptions}
 */
export const request = async ({
    method,
    url,
    data,
    baseURL = process.env.REACT_APP_GREBCOMMERCE_BACKEND_API_URL,
    headers,
}: Request) => {
    const config: AxiosRequestConfig = {
        method,
        url,
        baseURL,
        headers,
        [method === 'GET' ? 'params' : 'data']: data,
    };

    try {
        const response = await axios(config);

        return response.data;
    } catch (error: any) {
        const { data = {} } = error?.response || {};

        console.error(`Unable to fetch and parse as JSON from '${url}'`, config);

        throw { ...parseError(data) };
    }
};

export const get = (url: string, data?: any, baseURL?: string, headers?: any) =>
    request({
        method: 'GET',
        url,
        data,
        baseURL,
        headers,
    });

export const post = (url: string, data: any, baseURL?: string, headers?: any) =>
    request({
        method: 'POST',
        url,
        data,
        baseURL,
        headers,
    });

export const put = (url: string, data: any, baseURL?: string, headers?: any) =>
    request({
        method: 'PUT',
        data,
        baseURL,
        url,
        headers,
    });

export const del = (url: string, data: any, baseURL?: string, headers?: any) =>
    request({
        method: 'DELETE',
        data,
        baseURL,
        url,
        headers,
    });
