import { BannerCssVariables } from 'config/branding/interfaces/banner';

export const bannerCssVariables: BannerCssVariables = {
    '--color-bg-banner-black': 'var(--color-base-brand-black)',
    '--color-bg-banner-red': 'var(--color-base-brand-red)',
    '--color-bg-banner-white': 'var(--color-base-white)',
    '--color-border-banner-black': 'var(--color-base-brand-black)',
    '--color-border-banner-red': 'var(--color-base-brand-red)',
    '--color-border-banner-white': 'var(--color-base-brand-black)',
    '--color-text-banner-black': 'var(--color-base-white)',
    '--color-text-banner-red': 'var(--color-base-white)',
    '--color-text-banner-white': 'var(--color-base-brand-black)',
};
